import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import UserDefault from '~/components/UserDefault';
import { useDefaultData } from '~/hooks/DefaultData';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';
import { QuestionOS } from '../Simulation/Form';
import { SCFormAC, SCFormCT } from './schemas';
import { Container } from './styles';

interface IParams {
  builderId?: string;
  bdi?: string;
  osId: string;
}

const ServiceOrderComplementary: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const [allowComplementaryForm, setAllowComplementaryForm] = useState(false)
  const [completaryData, setComplementaryData] = useState<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT> | null>(null)
  const { validateEngineerData } = useProfile();
  const { getServiceOrderData, serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  const handleGetComplementaryData = useCallback(async () => {
    try {
      if (!serviceOrder || !serviceOrder?.id) throw new Error('');

      const response = await api.get<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT>>(`builders/${serviceOrder?.id}/complementary-data`);

      if (!response || response.status !== 200) throw new Error("Don't have data to show");

      // if (response.data && (serviceOrder.status_id !== 2 && serviceOrder.status_id  !== 3 && serviceOrder.status_id !== 5)) setAllowComplementaryForm(true);

      setComplementaryData(response.data)
    } catch (error) {
      // do anything
    }
  }, [setAllowComplementaryForm, serviceOrder])

  const handleFowardFlow = useCallback((data: Partial<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT>> | null) => {  
      try {
        if(!data) {
          throw new Error('Não foi possivel concluir esta etapa, tente novamente!')
        }

        if(sessionStorage) {
          sessionStorage.setItem(`OS-MCF@${serviceOrder.finalidade?.finalidade}`, JSON.stringify(data))
        }
        
        if (params.builderId) {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/nova-os/${params.builderId}/${params.bdi}`
          );
        } else if (params.osId !== undefined) {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/${params.osId}`
          );
        } else {
          history.push(`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento`);        
        }      
      } catch (error: any) {
        Swal.fire({
          icon: 'warning',
          title: 'Dados complementares',
          text: error?.message || 'No momento não é possivel continar'
        })
      }
  }, [history, params.bdi, params.builderId, params.osId]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  useEffect(() => {
    if (params.osId) {
      setDefaultData();
      getServiceOrderData(parseInt(params.osId, 10));
    }
  }, [params.osId, getServiceOrderData, setDefaultData]);

  useEffect(() => {
    if (!serviceOrder?.finalidade_id) {
      history.push('/finalidades');
      return;
    }
    if (!serviceOrder?.simulacao?.vlrFinanciado) {
      history.push(`/simulador/${params.osId ?? ''}`);
    }
  }, [
    history,
    serviceOrder?.finalidade_id,
    serviceOrder?.simulacao?.vlrFinanciado,
  ]);

  useEffect(() => {
      if (serviceOrder.finalidade_id === 1 || serviceOrder.finalidade_id === 2) {
        handleGetComplementaryData()
      } else {
        // setAllowComplementaryForm(true)
      }
    }, [])

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <UserDefault DadosCliente />
          <div className="px-4">
            <p className="text-gray mb-4 px-5">
              Para nossa equipe analisar o perfil do seu financiamento
              <br />
              solicitado iremos precisar dos dados a seguir:
            </p>
          </div>

        <span className='w-full rounded-xl bg-white border-[1px] border-gray-300 flex flex-col shadow-lg p-8 mt-4'>
          {
            serviceOrder.finalidade_id === 1 && (<QuestionOS.AC defaultData={completaryData} id={serviceOrder.id} allowFlowCB={(args) => handleFowardFlow(args)} />)
          }
          {
            serviceOrder.finalidade_id === 2 && (<QuestionOS.CT defaultData={completaryData} id={serviceOrder.id} allowFlowCB={(args) => handleFowardFlow(args)} />)
          }
        </span>
        </div>
      </div>
    </Container>
  );
};

export default ServiceOrderComplementary;
