import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserDefault from '~/components/UserDefault';
import { useDefaultData } from '~/hooks/DefaultData';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { SCFormAC, SCFormCT } from '../ServiceOrderComplementary/schemas';
import Location from './Partials/Location';
import MonetaryData from './Partials/MonetaryData';
import PersonalData from './Partials/PersonalData';
import { Container, Step } from './styles';

interface IParams {
  builderId?: string;
  bdi?: string;
  osId: string;
}

const ServiceOrderOpening: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const { validateEngineerData } = useProfile();
  const [step, setStep] = useState<number>(1);
  const [stepDescription, setStepDescription] = useState('Dados pessoais');
  const { getServiceOrderData, serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  useEffect(() => {
    if (params.osId) {
      setDefaultData();
      getServiceOrderData(parseInt(params.osId, 10));
    }
  }, [params.osId, getServiceOrderData, setDefaultData]);

  useEffect(() => {
    if (!serviceOrder?.finalidade_id) {
      history.push('/finalidades');
      return;
    }
    if (!serviceOrder?.simulacao?.vlrFinanciado) {
      history.push(`/simulador/${params.osId ?? ''}`);
    }
  }, [
    history,
    serviceOrder?.finalidade_id,
    serviceOrder?.simulacao?.vlrFinanciado,
  ]);

  useEffect(() => {
    switch (step) {
      case 2:
        setStepDescription('Modalidades e localização');
        break;
      case 3:
        setStepDescription('Dados monetários');
        break;
      default:
        setStepDescription('Dados pessoais');
        break;
    }
  }, [step]);

  useEffect(() => {
    if(!sessionStorage) return;
    
    const OS_COMPLEMENTARY_DATA = sessionStorage.getItem(`OS-MCF@${serviceOrder.finalidade?.finalidade}`) as Partial<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT>> | null

    if(!OS_COMPLEMENTARY_DATA) history.goBack()
  },[])

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <UserDefault DadosCliente />
          <div className="px-4">
            <p className="text-gray mb-4 px-5">
              Para nossa equipe analisar o perfil do seu financiamento
              <br />
              solicitado iremos precisar dos dados a seguir: 
            </p>
          </div>

          <div className="px-5">
            {/* <div className="col-12 container-box-information">
              <div className="d-flex align-items-center px-5 box-rezise">
                <p className="mb-0 fw-semibold">
                  Adicionar informações da Caixa{' '}
                  <button
                    type="button"
                    className="button_clickeame bg-transparent text-primary fw-semibold border-0"
                  >
                    <BsPlusCircleFill className="mb-1 mx-2" size={20} />
                  </button>
                </p>
              </div>
            </div> */}

            <div className="col-12">
              <div className="d-flex flex-column align-items-end px-4">
                <p className="mb-0 fw-medium text-light-gray">
                  Parte{' '}
                  <span className="fw-bold">
                    {step.toString().padStart(2, '0')}
                  </span>
                  /03
                </p>
                <p className="text-green fw-bold">{stepDescription}</p>
              </div>
            </div>
            {step === 1 && (
              <Step>
                <PersonalData setStep={setStep} />
              </Step>
            )}
            {step === 2 && (
              <Step>
                <Location setStep={setStep} step={step} />
              </Step>
            )}
            {step === 3 && (
              <Step>
                <MonetaryData setStep={setStep} step={step} />
              </Step>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ServiceOrderOpening;
