import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { addYears, format } from 'date-fns';
import { RiInformationFill } from 'react-icons/ri';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { formatPrice } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';
import { Box, Title } from './styles';

interface ICardTotalValueFInancedProps extends PropsWithChildren<any> {
  os?: IMServiceOrder
  rollback?: (args: boolean) => unknown
}

const CardTotalValueFInanced: React.FC<ICardTotalValueFInancedProps> = ({ os,rollback }: ICardTotalValueFInancedProps) => {
  const { serviceOrder: OSState } = useServiceOrder();

  // @ts-ignore
  const serviceOrder: Required<IMServiceOrder> = os || OSState
  const HAS_MCF_AMORTIZATION = useMemo(() => {
    return serviceOrder?.simulacao?.amortizacao?.amortizacao.toUpperCase().includes('MCF')
  },[serviceOrder])
  const HAS_MCF_VALID_AMOUNT = useMemo(() => {
    if(!HAS_MCF_AMORTIZATION || !serviceOrder?.simulacao?.vlrFinanciado) return true;


    if((serviceOrder.finalidade.id === 1 && serviceOrder.simulacao.vlrFinanciado < 500000.00) || (serviceOrder.finalidade.id === 2 && serviceOrder.simulacao.vlrFinanciado < 300000.00)) {
      return false;
    }

    return true;
  },[HAS_MCF_AMORTIZATION, serviceOrder])

  useEffect(() => {
    if(!HAS_MCF_AMORTIZATION || HAS_MCF_VALID_AMOUNT) return;

    if(!HAS_MCF_VALID_AMOUNT && rollback && typeof rollback === 'function') {
      rollback(!HAS_MCF_VALID_AMOUNT)
    }
  }, [HAS_MCF_AMORTIZATION,HAS_MCF_VALID_AMOUNT])

  return (
    <>
        
      {serviceOrder.simulacao && (
        <Box className="px-4 py-5 p-lg-5 h-100">
          <div className="d-flex justify-content-between">
            <Title
              color={
                serviceOrder.simulacao.amortizacao?.color
                  ? serviceOrder.simulacao.amortizacao.color
                  : '#000'
              }
              className=""
            >
              {serviceOrder?.simulacao?.amortizacao?.amortizacao}
            </Title>
            <button type="button" className="border-0 bg-transparent">
              <RiInformationFill size={22} color="#fff" />
            </button>
          </div>
          <div className="mt-4 mb-5 row">
            <div className="col-12">
              <span>Valor total financiado</span>
              <div className="d-flex align-items-center gap-2 mt-2">
                <p className="h3 text-dark-gray mb-0 fw-semibold">
                  {serviceOrder.simulacao.vlrFinanciado
                    && formatCurrency(serviceOrder.simulacao.vlrFinanciado)}
                </p>
                <p data-showme={!HAS_MCF_VALID_AMOUNT} className='hidden data-[showme=true]:flex text-sm text-red-500 font-semibold'>
                  *A linha de crédito não é elegivel para você
                </p>
              </div>
            </div>
            {(serviceOrder.finalidade_id === 3
              || serviceOrder.finalidade_id === 4) && (
                <div className="col-6">
                  <span>Valor de entrada</span>
                  <div className="d-flex align-items-center mt-2">
                    <p className="h3 text-dark-gray mb-0 fw-semibold">
                      {serviceOrder.simulacao.vlrEntrada
                        && formatCurrency(serviceOrder.simulacao.vlrEntrada)}
                    </p>
                  </div>
                </div>
              )}
          </div>
          <div>
            <div className="d-flex pb-3 position-relative">
              <div className="d-flex justify-content-center chain chain-top my-2" />
              <div className="installment d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <p className="mb-0">Primeira Parcela</p>
                  <small>{format(new Date(), 'dd/MM/yyyy')}</small>
                </div>
                <div>
                  <p className="h5">
                    {serviceOrder.simulacao?.vlrParcInic
                      && formatPrice(serviceOrder.simulacao?.vlrParcInic)}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex pt-3 position-relative">
              <div className="d-flex justify-content-center chain chain-bottom my-auto" />
              <div className="installment d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <p className="mb-0">Última Parcela</p>
                  <small>
                    {format(
                      addYears(new Date(), Math.round(Number(serviceOrder.simulacao.prazo)) || 0),
                      'dd/MM/yyyy'
                    )}
                  </small>
                </div>
                <div>
                  <p className="h5">
                    {serviceOrder.simulacao?.vlrParcFinal
                      && formatPrice(serviceOrder.simulacao?.vlrParcFinal)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default CardTotalValueFInanced;
